import { nextTick } from "vue";
import { Position, dropdownDirections } from "./types";

export const computePosition = async (
    clickArea: HTMLElement | undefined,
    dropdownArea: HTMLElement | undefined,
    direction: dropdownDirections
): Promise<Position | null> => {
    if (!clickArea) return null;
    const { top, left, right, height, width } = clickArea.getBoundingClientRect();
    let dropdownSpacing = 4;

    let positionLeft, positionTop;

    switch (direction) {
        case "right": {
            positionLeft = right + dropdownSpacing;
            positionTop = top - dropdownSpacing;
            break;
        }
        // "automatic" | "bottom"
        default: {
            positionLeft = left;
            positionTop = top + height + dropdownSpacing;
        }
    }

    if (!dropdownArea) return { top: positionTop, left: positionLeft };
    await nextTick();

    const xExceeds = dropdownArea.offsetWidth + left;
    const yExceeds = dropdownArea.offsetHeight + positionTop + dropdownSpacing;
    if (xExceeds >= window.innerWidth) {
        positionLeft = left - dropdownArea.offsetWidth + width;
    }
    if (yExceeds >= window.innerHeight) {
        const newY = top - dropdownArea.offsetHeight - dropdownSpacing;
        if (newY > 0) {
            positionTop = newY;
        }
    }
    return { top: positionTop, left: positionLeft };
};
